.privacy-policy-container {
    padding: 20px;
    margin: auto;
    max-width: 800px;
    font-family: Arial, sans-serif;
    color: #333;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #007BFF;
  }
  
  h2 {
    margin-top: 20px;
    color: #0056b3;
  }
  
  p {
    line-height: 1.6;
  }
  
  ul {
    list-style: none;
    margin-top: 10px;
    padding-left: 0;
  }
  
  ul li {
    padding: 5px 0;
    position: relative;
    text-align:justify;
    text-align:center;
    
  }
  
   /* li::before {
    content: "•";
    color: #007BFF;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
   */
  @media (max-width: 768px) {
    .privacy-policy-container {
      padding: 15px;
    }
  
    h1 {
      font-size: 1.5em;
    }
  
    h2 {
      font-size: 1.2em;
    }
  
    p, ul li {
      font-size: 1em;
    }
  }
  
  @media (max-width: 480px) {
    .privacy-policy-container {
      padding: 10px;
    }
  
    h1 {
      font-size: 1.2em;
    }
  
    h2 {
      font-size: 1em;
    }
  
    p, ul li {
      font-size: 0.9em;
    }
  }
  