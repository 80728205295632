 /* General styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f9f9f9;
  font-family: sans-serif;
}

.landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 30px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.logo {
  height: 50px;
  margin: 0 10px;
}

.navigation {
  display: flex;
  flex-wrap: wrap;
}

.navigation a {
  margin: 0 15px;
  color: #333;
  text-decoration: none;
  padding: 10px 15px;
  transition: color 0.3s;
}

.navigation a:hover {
  color: #007BFF;
}

.contact-button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-button .phone-icon {
  margin-right: 5px;
}

.contact-button:hover {
  background-color: #218838;
}

.toggle-button {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  position: relative;
  padding: 20px;
}

.left {
  width: 100%;
}

.background-img {
  width: 100%;
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.right {
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-container {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-top: 140px;
}

.form-container h2 {
  color: #dc3545;
  font-size: 24px;
}

.form-container p {
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
  font-weight: bold;
}

.form-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

form {
  display: flex;
  flex-direction: column;
}

form input,
form textarea {
  padding: 12px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

form input:focus,
form textarea:focus {
  outline: none;
  border-color: #007BFF;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);
}

form button {
  padding: 12px;
  background-color: #dc3545;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 50%;
  margin: 0 auto;
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

form button:hover {
  background-color: #c82333;
  transform: translateY(-2px);
}

@media (min-width: 768px) {
  .content {
    flex-direction: row;
    justify-content: center;
  }

  .right {
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
  }

  .toggle-button {
    display: none;
  }
}

@media (max-width: 768px) {
  .content {
    flex-direction: column-reverse;
    align-items: center;
  }

  .left {
    width: 100%;
    order: 2;
  }

  .right {
    position: static;
    transform: none;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    order: 1;
  }

  .form-container {
    margin-top: 50px;
  }

  .background-img {
    height: 200px;
  }

  .navigation {
    display: none;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }

  .navigation.open {
    display: flex;
  }

  .navigation a {
    margin: 10px 0;
  }

  .toggle-button {
    display: block;
  }
}

/* Toastify styles */
.Toastify__toast {
  border-radius: 5px;
  font-family: Arial, sans-serif;
}

.Toastify__toast--success {
  background-color: white;
}

.Toastify__toast--error {
  background-color: #dc3545;
}

/* Message popup styles */
.popup-message {
  background-color: #dff0d8;
  color: #3c763d;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  text-align: center;
}
