.custom-btn {
  background-color: green;
  color: white;
  width: 100%;
  text-align: center;
}

.custom-btn:hover {
  background-color: darkgreen;
  color: white;
}

@media (max-width: 768px) {
  .card-container {
      flex-direction: column;
      align-items: center;
  }

  .card1 {
      width: 100%;
      margin: 1rem 0;
      height:100%;
  }
}

@media (min-width: 769px) {
  .card-container {
      flex-direction: row;
      justify-content: center;
  }

  .card1 {
      width: 20rem;
      margin: 1rem;
  }
}
