/* Container for the video */
.video-container {
    position: relative;
    padding-top: 39.375%; /* Aspect ratio for height 70% of 16:9 video */
    height: 0;
    overflow: hidden;
}

/* Responsive iframe for the video */
.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; /* Full height of the container */
}

/* Media query for larger screens */
@media (min-width: 768px) {
    .video-container {
        padding-top: 21.875%; /* Adjust aspect ratio for height 70% */
    }
}
