.features-section {
  text-align: center;
  padding: 20px;
  position: relative;
}

.title {
  font-size: 24px;
  color: #8B5E3C; /* Replace with your desired color */
  margin-bottom: 20px;
}

.features-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feature {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  background-color: green; /* Background color set to green */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: white; /* Text color set to white */
}

.feature-icon img {
  width: 50px;
  height: 50px;
}

.feature-title {
  font-size: 18px;
  margin: 10px 0;
  color: white; /* Title color set to white */
}

.feature-list {
  padding: 0;
  color: white; /* List text color set to white */
}

.feature-list li {
  text-align: left;
  padding: 5px 0;
  font-size: 14px;
}

@media (min-width: 768px) {
  .features-container {
      flex-direction: row;
      justify-content: space-between;
  }

  .feature {
      flex: 1;
      margin: 0 10px;
  }
}

.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: white;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  z-index: 1000;
}

.whatsapp-button:hover {
  background-color: #128c7e;
}
.feature-list li{
  color:white;
}