.responsive-section {
  text-align: center;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.section-header {
  background-color: white;
  color: black;
  padding: 10px;
  border-radius: 5px 5px 0 0;
  font-size: 1.5em;
  font-weight: bold;
  /* font-family: "Times New Roman", Times, serif; */
}

.section-content {
  background-color: white;
  padding: 20px;
  border-radius: 0 0 5px 5px;
  font-family:"san-serif"
}

.section-content p {
  margin: 10px 0;
  line-height: 1.6;
}

.modal {
  display: block; /* Ensure modal is displayed when showModal is true */
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  padding: 50px;
  box-sizing: border-box;
}

.modal-content {
  background-color: #fefefe;
  border-radius: 5px;
  padding: 20px;
  position: relative;
  margin: auto;
  max-width: 600px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5em;
  cursor: pointer;
}

@media (max-width: 600px) {
  .section-header {
    font-size: 1.2em;
  }

  .section-content {
    padding: 10px;
  }

  .section-content p {
    font-size: 0.9em;
  }
}
.site{
   background-color:teal;
   color:white;
   border-style:none;
   padding:8px;
   border-radius:20px;
   margin-top:5px;
}

.site:hover{
  background-color:red;
}