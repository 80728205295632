/* Base Styles */
.slider-container {
  position: relative;
  padding: 30px;
  background-color: white;
  text-align: center;
}

.slider-title {
  color: black;
  font-size: 24px;
  margin-bottom: 20px;
  width: 30%;
  margin-left: 35%;
  padding: 10px;
}

.slide {
  padding: 10px;
}

.slide img {
  width: 100%;
  border-radius: 8px;
}

ul {
  text-align: justify;
}

ul li {
  color: teal;
}

/* Media Queries */
@media (max-width: 1024px) {
  .slider-title {
    font-size: 20px;
    width: 50%;
    margin-left: 25%;
    padding: 8px;
  }
}

@media (max-width: 600px) {
  .slider-title {
    font-size: 16px;
    width: 70%;
    margin-left: 15%;
    padding: 6px;
  }
}
