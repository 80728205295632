.slider1-container {
    padding: 20px;
    background-color: #f5f5dc; /* Light green background */
    text-align: center;
  }
  
  .slider1-title {
    color: #a52a2a; /* Brown color */
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .slider1 {
    display: flex;
    justify-content: center;
    margin-bottom: 20px; /* Add margin between sliders */
  }
  
  .slide1 {
    padding: 10px;
    width: 50%; /* Display two items per row initially */
    box-sizing: border-box; /* Include padding in width calculation */
  }
  
  .slide1 img {
    width: 100%;
    max-height: 200px; /* Adjust the maximum height as per your requirement */
    object-fit: cover; /* Maintain aspect ratio and cover the container */
    border-radius: 8px;
  }
  
  @media (max-width: 768px) {
    .slide1 {
      width: 100%; /* Display one item per row on smaller screens */
    }
  }
  