/* Footer.css */
.footer {
  background-color: teal;
  color: white;
  padding: 40px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.footer-left,
.footer-right {
  flex: 1 1 45%;
  margin: 10px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.footer-left h3,
.footer-right h3 {
  font-size: 24px;
  margin-bottom: 20px;
  font-family: "monospace";
}

.footer-left p,
.footer-right p {
  font-size: 16px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  font-family: "monospace";
}

.footer-left p svg,
.footer-right p svg {
  margin-right: 10px;
}

.footer-left a,
.footer-right a {
  color: white;
  text-decoration: none;
}

.footer-left a:hover,
.footer-right a:hover {
  text-decoration: underline;
}

.map-container {
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
  height: 300px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px;
  border-radius: 10px;
  border: 1px solid #ccc;
  font-size: 14px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  color: #aaa;
}

.btn {
  background-color: #4caf50;
  color: white;
  width: 40%;
  border: none;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
}

.btn:hover {
  background-color: #45a049;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.privacy-link {
  bottom: 10px;
  right: 20px;
  font-size: 14px;
  color: white;
  text-decoration: none;
  padding: 10px;
  background-color: green;
  border-radius: 20px;
}

.privacy-link:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-left,
  .footer-right {
    flex: 1 1 100%;
    margin: 10px 0;
  }

  .map-container {
    width: 100%;
    height: 300px; /* Adjust height as needed for responsiveness */
  }

  .privacy-link {
    position: static; /* Update this */
    margin-top: 20px; /* Add this */
    text-align: center; /* Add this */
  }
}

@media (max-width: 480px) {
  .footer-left p {
    font-size: 14px;
  }

  .footer-left h3,
  .footer-right h3 {
    font-size: 20px;
  }

  .privacy-link {
    font-size: 12px; /* Update this */
  }
}

