body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.conditions-and-treatments {
  text-align: center;
  margin: 20px;
}

.tabs {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  list-style: none;
  justify-content: center;
  flex-wrap: wrap;
  
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-bottom: none;
  /* background-color: teal; */
  transition: background-color 0.3s;
}

.tab:hover {
  background-color: white;
  color:black;
}

.tab.active {
  background-color: white;
  border-bottom: 2px solid #f00;
  color:black;
}

.tab-content {
  border: 1px solid #ccc;
  padding: 20px;
  display: none;
  text-align: center;
}

.tab-content.active {
  display: block;
}

 

 

@media (max-width: 600px) {
  .tabs {
    flex-direction: column;
  }

  .tab {
    border-right: none;
  }

  .tab.active {
    border-bottom: 1px solid #f00;
  }
}
