.investment-section {
  padding: 20px;
  background-color: white; /* Light green background */
  color: teal;
  font-family: sans-serif;
}

.description {
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.2em;
  text-align: justify;
  position: relative;
}

.investment-section h1 {
  background-color: white;
  width: 30%;
  border-radius: 10px;
  margin-left: 35%;
  color: black;
  padding: 3px;
  text-align: center;
}

.banner {
  position: relative;
  background-image: url('./image/l4.jpg');
  background-size: cover;
  background-position: center;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-content {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

/* .banner h2 {
  color: #a52a2a; /* Brown color */
  /* margin: 0;
  font-size: 1.5em;
}  */


.description p {
  margin-bottom: 1em;
  line-height: 1.6;
}

/* Media Queries */
@media (max-width: 768px) {
  .banner {
    height: 200px;
  }

  /* .banner h2 {
    font-size: 1.2em;
  } */

  .description {
    font-size: 1.1em;
    padding: 0 10px;
  }

  .investment-section h1 {
    width: 50%;
    margin-left: 25%;
  }
}

@media (max-width: 480px) {
  .description {
    font-size: 1em;
    line-height: 1.4;
    padding: 0 5px;
  }

  .banner {
    height: 150px;
  }

  /* .banner h2 {
    font-size: 1em;
  } */

  .investment-section h1 {
    width: 70%;
    margin-left: 15%;
  }
}
